"use client"

import React, { useState, useContext } from "react";
import Modal from "components/feedback/Modal";
import RenderForm from "../RenderForm";
import Button from "components/forms/Button";
import clsx from "clsx";
import utils from 'styles/globals/utils.module.scss';
import Style from './FormModal.module.scss';

function FormModal({
    ctaText,
    backgroundColor, 
    marginTop,
    marginBottom,
    position,
    formControl,
    tradeStatus,
    customer_group_id
}) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const handleFormSubmit = (formData) => {
        toggleModal();
    };

    if (customer_group_id === 1 && tradeStatus === 'no') {
        return null;
    }
        

    return (
        <>
            <div 
                className={clsx(
                    Style.block,
                    utils[`justify_content_${position}`],
                    marginTop && utils[`mt_${marginTop}`],
                    marginBottom && utils[`mb_${marginBottom}`],                    
                )}
            >
                <Button 
                    onClick={toggleModal}
                    color={backgroundColor ?? "ocean"}
                >
                {ctaText}
                </Button>
            
                <Modal shouldOpen={isModalOpen} willClose={toggleModal} shouldCloseOnOverlayClick={false}>
                    {formControl && formControl.name && (
                        <h2 className="utils_fs_2__2nLTd utils_text_center__Us68A">
                            {formControl.name}
                        </h2>
                    )}
                    <RenderForm
                        formControl={formControl}
                        onSubmit={handleFormSubmit}
                        hideLegend
                        fullWidthForm
                        formClass={clsx(utils.p_0, utils.mt_4)}
                    />
                </Modal>
            </div>
        </>
    );
    
}

export default FormModal;